<template>
    <div class="profileAside">
        <router-link to="/profile" class="profileAsideItem text"
            >Общая информация</router-link
        >
        <router-link to="/profile/notifications" class="profileAsideItem text"
            >Настройка уведомлений</router-link
        >
        <router-link to="/profile/password" class="profileAsideItem text"
            >Смена пароля</router-link
        >
    </div>
</template>

<script>
export default {
    name: "ProfileAside",
};
</script>

<style lang="scss" scoped>
@import "./ProfileAside.scss";
</style>
