<template>
    <div class="pageHeader">
        <div class="pageHeader__breadcrumb">
            <b-breadcrumb :items="items"></b-breadcrumb>
        </div>
        <div class="pageHeader__title">
            <router-link v-if="link" :to="link" class="pageHeader__close">
                <img
                    alt="close"
                    class="pageHeader__closeIcon"
                    src="@/assets/img/common/arrowLeft.svg"
                />
            </router-link>
            <button
                v-else
                type="button"
                @click="$router.go(-1)"
                class="pageHeader__close"
            >
                <img
                    alt="close"
                    class="pageHeader__closeIcon"
                    src="@/assets/img/common/arrowLeft.svg"
                />
            </button>
            <p class="h1">{{ title }}</p>
        </div>
    </div>
</template>

<script>
export default {
    components: {},

    props: {
        items: {
            type: Array,
            default: () => {
                return [];
            },
        },
        title: {
            type: String,
            default: "",
        },
        link: String,
    },
};
</script>

<style lang="scss" scoped>
@import "pageHeader.scss";
</style>
