<template>
    <div>
        <router-link
            v-if="isLink && !isExternalLink"
            :to="link"
            :class="(extraClass || '') + ' button'"
            :disabled="isDisabled"
            :type="isButton ? 'button' : 'submit'"
            @click.native="isButton ? buttonClick($event) : onClick"
        >
            {{ title }}
        </router-link>
        <a
            v-else-if="isLink && isExternalLink"
            :href="link"
            target="_blank"
            :class="(extraClass || '') + ' button'"
            :disabled="isDisabled"
        >
            {{ title }}
        </a>
        <button
            v-else
            :class="(extraClass || '') + ' button'"
            :disabled="isDisabled"
            :type="isButton ? 'button' : 'submit'"
            @click="isButton ? buttonClick($event) : onClick"
        >
            {{ title }}
        </button>
    </div>
</template>

<script>
export default {
    name: "Button",

    props: {
        title: String,
        isButton: Boolean,
        extraClass: String,
        isDisabled: Boolean,
        onClick: Function,
        isLink: Boolean,
        isExternalLink: Boolean,
        link: String,
    },

    methods: {
        buttonClick(event) {
            this.$root.$emit('buttonClickEvent', event);
        }
    }
};
</script>

<style lang="scss" scoped>
@import "./button.scss";
</style>
