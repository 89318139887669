<template>
    <Layout>
        <PageHeader :items="items" :title="title" />

        <div class="profileSection">
            <ProfileAside />
            <div class="profileContent">
                <div class="profileContentRow">
                    <div class="profileContentCol">
                        <div class="profileBox">
                            <p class="title1 profileBox__title">смена пароля</p>
                            <form @submit.prevent="submitForm()">
                                <div class="form">
                                    <div class="formgroup">
                                        <label class="formgroup__label">
                                            Пароль
                                        </label>
                                        <Input
                                            type="password"
                                            placeholder="Введите новый пароль"
                                            v-model="form.password"
                                            :isError="isErrorPass"
                                            :errorText="passErrorMessage"
                                            :onChange="
                                                (e) => onChangeSetValue(e, 'password')
                                            "
                                        />
                                    </div>
                                    <div class="formgroup">
                                        <label class="formgroup__label">
                                            Повторите пароль
                                        </label>
                                        <Input
                                            type="password"
                                            placeholder="Повторите пароль"
                                            v-model="form.passwordRetyped"
                                            :isError="isErrorPass"
                                            :errorText="passErrorMessage"
                                            :onChange="
                                                (e) =>
                                                    onChangeSetValue(e, 'passwordRetyped')
                                            "
                                        />
                                    </div>
                                </div>

                                <br />
                                <ButtonWithAction
                                    type="submit"
                                    class="profileMainSubmit"
                                    title="Изменить пароль"
                                    :isButton="false"
                                />
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
</template>

<script>
import Layout from "@/views/layouts/Layout";
import Input from "@/views/components/Input/Input";
import PageHeader from "@/views/partials/pageHeader/pageHeader";
import ProfileAside from "@/views/partials/ProfileAside/ProfileAside";
import Button from "@/views/components/Button/Button";
import ButtonWithAction from "@/views/components/ButtonWithAction/Button";
import axios from "axios";

export default {
    name: "profilePassword",

    components: {
        Layout,
        PageHeader,
        ProfileAside,
        Input,
        Button,
        ButtonWithAction,
    },

    data() {
        return {
            isErrorPass: false,
            passErrorMessage: "",
            form: {
                password: "",
                passwordRetyped: "",
            },
            title: "Профиль",
            items: [
                {
                    text: "Магазин программ",
                    to: "/",
                },
                {
                    text: "Профиль",
                    active: true,
                },
            ],
        };
    },

    methods: {
        onChangeSetValue: async function(value, key) {
            this.form[key] = value;
        },
        resetInputs: function() {
            this.form.password = "";
            this.form.passwordRetyped = "";
        },
        submitForm: async function() {
            if (this.form.password && this.form.password !== "") {
                if (this.form.passwordRetyped && this.form.passwordRetyped !== "") {
                    if (this.form.password === this.form.passwordRetyped) {
                        const savePasswordResult = await axios.post(
                            "/profile/edit/password",
                            this.form
                        );
                        if (
                            savePasswordResult &&
                            savePasswordResult.data &&
                            savePasswordResult.data.result
                        ) {
                            this.resetInputs();
                            this.isErrorPass = false;
                            await this.$store.dispatch("setAlert", {
                                alert: {
                                    isActive: true,
                                    type: "success",
                                    text: "Пароль успешно изменен",
                                },
                            });
                        } else {
                            await this.$store.dispatch("setAlert", {
                                alert: {
                                    isActive: true,
                                    type: "error",
                                    text: "Ошибка при сохранении пароля",
                                },
                            });
                        }
                    } else {
                        (this.isErrorPass = true),
                            (this.passErrorMessage = "Пароли не совпадают");
                        await this.$store.dispatch("setAlert", {
                            alert: {
                                isActive: true,
                                type: "error",
                                text: "Пароли не совпадают",
                            },
                        });
                    }
                } else {
                    await this.$store.dispatch("setAlert", {
                        alert: {
                            isActive: true,
                            type: "error",
                            text: "Повторите пароль",
                        },
                    });
                }
            } else {
                (this.isErrorPass = true),
                    (this.passErrorMessage = "Введите пароль"),
                    await this.$store.dispatch("setAlert", {
                        alert: {
                            isActive: true,
                            type: "error",
                            text: "Введите пароль",
                        },
                    });
            }
        },
    },
};
</script>

<style lang="scss" scoped>
@import "profilePassword.scss";
</style>
